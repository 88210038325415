<template>
    <div>
        <!-- 头部信息区-->
        <div class="top">
            <el-row :gutter="35">
                <!--图片-->
                <el-col :span="10">
                    <!--封面图片-->
                    <el-image v-if="data.coverImgUrl" class="img" fit="cover" :src="data.coverImgUrl"/>
                </el-col>
                <!--信息-->
                <el-col :span="14">
                    <div style="height: 215px">
                        <!--标题-->
                        <div class="top-title">
                            {{ data.name }}
                        </div>
                        <div v-if="data.price" class="top-price">
                            ￥{{ ((data.price / 100).toFixed(2)) }}
                        </div>
                    </div>
                    <div v-if="!isBuy">
                        <el-button type="warning" style="font-size: 18px;" @click="toBuy()">
                            {{ data.price === 0 ? '免费领取' : '前往购买' }}
                        </el-button>
                        <el-button type="warning" style="margin-left: 20px; font-size: 18px;">
                            咨询
                        </el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!--下方内容区-->
        <div class="content">
            <!--导航栏-->
            <div class="content-nav">
                <div class="nav-item" :class="{active: currentIndex === 0}" @click="currentIndex = 0">
                    课程介绍
                </div>
                <div class="nav-item" :class="{active: currentIndex === 1}" @click="currentIndex = 1">
                    课程目录
                </div>
            </div>
            <!--内容展示-->
            <div class="content-info">
                <!--详情图片-->
                <div v-if="currentIndex === 0">
                    <!--详情图片-->
                    <el-image
                        v-if="data.descImgUrl"
                        style="width: 100%"
                        class="img"
                        fit="cover"
                        :src="data.descImgUrl"/>
                </div>
                <!--目录内容-->
                <div v-if="currentIndex === 1">
                    <!--空状态-->
                    <el-empty v-if="isEmpty" description="好像什么都没有~"></el-empty>
                    <el-timeline v-else>
                        <el-timeline-item color="#f29100" v-for="(item, key) in packageData" :key="key">
                            <el-card class="course-card" @click.native="toDetail(item)">
                                <div class="course-title">
                                    <el-row>
                                        <el-col :span="20">
                                            {{ item.whPackageModule.name }}
                                        </el-col>
                                        <el-col :span="4" style="text-align: right;">
                                            <el-tag
                                                :type="['success', 'primary', 'warning'][item.whPackageModule.contentType]">
                                                {{ ['录播课', '直播课', '测试卷'][item.whPackageModule.contentType] }}
                                            </el-tag>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div v-if="item.whPackageModule.contentType !== 2" class="course-teacher">
                                    <el-avatar
                                        :src="item.whMaterialImg.imgUrl"
                                        :size="30"/>
                                    <div class="teacher-name">
                                        {{ item.whMaterialTeacher.name }}
                                    </div>
                                </div>
                                <div v-else class="course-teacher"
                                     :style="{color: item.whPaperRecord ? '#ff9900' : '#7f8186'}">
                                    <div>
                                        {{ item.whPaperRecord ? '√ 已完成 ' : '未做过' }}
                                    </div>
                                    <div style="margin-left: 20px;">
                                        {{ item.whPaperRecord.createTime }}
                                    </div>
                                </div>
                            </el-card>
                        </el-timeline-item>
                    </el-timeline>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "packageDetail",
    data() {
        return {
            list: [
                {
                    name: '录播课第2期',

                }
            ],
            currentIndex: 0,
            packageData: [],
            currentPage: 1,
            pageSize: 10,
            data: {},
            isBuy: true,
            isLimitQuantity: false,
            remainQuantity: 0,
            isLimitEnd: false,
            isEmpty: false
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.getData()
        } else {
            this.$router.go(-1)
        }
    },
    methods: {
        // 获取学习包数据
        getData() {
            // 获取学习包详情
            this.$api.get('/package/index/getPackageDetail', {
                params: {
                    id: this.$route.params.id
                }
            }).then(res => {
                this.data = res.data
                this.packageData = res.packageModuleList
                this.isEmpty = !this.packageData.length
                // 判断是否购买过
                this.isBuy = res.isBuy
                if (this.isBuy) {
                    this.currentIndex = 1
                }
            }).catch(err => {
                console.log(err)
            })
        },
        // 前往详情内容
        toDetail(item) {
            if (this.data.id) {
                if (this.isBuy) {
                    if (item.whPackageModule.contentType === 0) {
                        // 录播课程
                        this.$router.push('/course/index/' + item.whPackageModule.contentId)
                    } else if (item.whPackageModule.contentType === 1) {
                        // 直播课程
                        this.$router.push('/live/index/' + item.whPackageModule.contentId)
                    } else if (item.whPackageModule.contentType === 2) {
                        // 暂不开通
                        this.$message.warning('当前暂未开通模考系统，请在法教授APP内完成练习')
                    }
                } else {
                    this.$message.warning('购买学习包后，即可解锁全部内容')
                }
            }
        },
        // 购买逻辑
        toBuy() {
            // 如果是免费的，可以直接获取
            if (this.data.price === 0) {
                this.$api.get('/package/index/getFreePackage', {
                    params: {
                        id: this.$route.params.id
                    }
                }).then(() => {
                    this.$message.success('免费领取成功！')
                    // 领取成功，刷新数据
                    this.getData()
                }).catch(err => {
                    console.log(err)
                })
            } else {
                // 如果是收费的，直接跳转到淘宝
                window.open(this.data.buyLink)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.top {
    background-color: #FFFFFF;
    padding: 30px;

    .img {
        border-radius: 3px;
        height: 270px;
        width: 100%;
    }

    .top-title {
        color: #333333;
        font-size: 28px;
        font-weight: 500;
        margin-top: 10px;
    }

    .top-price {
        font-size: 26px;
        color: #d20101;
        margin-top: 30px;
        margin-bottom: 10px;
    }
}

.content {
    margin-top: 20px;
    background-color: #FFFFFF;

    .content-nav {
        background: #f7f9fa;

        .nav-item {
            display: inline-block;
            height: 60px;
            padding: 15px 40px;
            line-height: 30px;
            font-size: 20px;
            color: #a1a4b3;
            cursor: pointer;

            &.active {
                color: #262937;
                font-weight: 500;
                background: #fff;
            }
        }
    }

    .content-info {
        padding: 30px;

        .course-card {
            cursor: pointer;
            padding: 10px 10px;

            &:hover {
                transition: all 0.2s;
                transform: translate3d(0, -8px, 0);
            }
        }

        .course-title {
            font-size: 18px;
            font-weight: 500;
            color: #262937;
        }

        .course-teacher {
            padding-top: 10px;
            display: flex;
            align-items: center;

            .teacher-name {
                font-size: 15px;
                font-weight: 400;
                line-height: 1;
                color: #787c8d;
                margin-left: 20px;
            }
        }
    }
}
</style>